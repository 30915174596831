




















































































import { Vue, Component } from "vue-property-decorator";
import firebase from "@/plugins/firebase";

interface DownloadItem {
  "Başvuru Id": string;
  "Burs Adı": string;
  "Eğitim Seviyesi": string;
  "Üye Adı": string;
  "Üye e-Posta": string;
  "Öğrenci Adı": string;
  "Öğrenci TCKN": string;
  "Öğrenci e-Posta": string;
  "Öğrenci Telefon": string;
  Durum: string;
  "Baş. Tarihi": string;
}

@Component({
  metaInfo: {
    title: "Burs Başvuruları"
  }
})
export default class AdminHome extends Vue {
  search = "";
  loading = false;
  headers = [
    {
      text: "Üye",
      align: "start",
      filterable: true,
      value: "member.email"
    },
    { text: "Öğrenci", value: "form.student.fullName" },
    { text: "Burs", value: "scholarshipId" },
    { text: "Durum", value: "status" },
    { text: "Oluşturulma", value: "dateCreated" },
    { text: "", value: "actions", sortable: false, width: "50px" }
  ];
  items = [] as ScholarshipApplication[];
  selectedItem = {};
  scholarships = [] as Scholarship[];

  downloadData = [] as DownloadItem[];

  toTitleCase(str: string) {
    const arr = str.toLocaleLowerCase("tr-TR").split(" ");
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toLocaleUpperCase("tr-TR") + arr[i].substr(1);
    }
    return arr.join(" ");
  }

  async getItems() {
    this.loading = true;

    try {
      this.items = [];

      // Get all apps
      const query = await firebase
        .firestore()
        .collection("/apps/kahev-org/scholarshipApps")
        .orderBy("dateCreated", "desc")
        .get();

      query.forEach(doc => {
        if (doc.exists) {
          let newItem = {} as ScholarshipApplication;
          newItem = doc.data() as ScholarshipApplication;
          newItem.id = doc.id;

          this.items.push(newItem);

          // İndirme verisini oluştur
          const downloadItem = {
            "Başvuru Id": newItem.id,
            "Burs Adı": this.getScholarshipById(newItem.scholarshipId)?.title,
            "Eğitim Seviyesi": newItem.form.eduLevel,
            "Üye Adı": newItem.member.fullName,
            "Üye e-Posta": newItem.member.email,
            "Öğrenci Adı": newItem.form.student.fullName,
            "Öğrenci TCKN": newItem.form.student.tckn,
            "Öğrenci e-Posta": newItem.form.student.email,
            "Öğrenci Telefon": newItem.form.student.phone,
            Durum: this.getScholarshipStatus(newItem.status),
            "Baş. Tarihi": newItem.dateCreated.toDate().toLocaleString("tr-TR")
          } as DownloadItem;

          this.downloadData.push(downloadItem);
        }
      });

      this.loading = false;
    } catch (err) {
      this.loading = false;
      this.$notify({
        type: "error",
        text: `Başvuru listesi alınamadı.`
      });
      console.error(err);
    }
  }

  async getScholarships() {
    try {
      this.scholarships = [];

      // Get all scholarships
      const query = await firebase
        .firestore()
        .collection("/apps/kahev-org/scholarships")
        .get();

      query.forEach(doc => {
        if (doc.exists) {
          let newScholarship = {} as Scholarship;
          newScholarship = doc.data() as Scholarship;
          newScholarship.id = doc.id;

          this.scholarships.push(newScholarship);
        }
      });
    } catch (err) {
      this.$notify({
        type: "error",
        text: `Burs listesi alınamadı.`
      });
      console.error(err);
    }
  }

  getScholarshipById(id: string): Scholarship {
    return this.scholarships.find(s => s.id == id) as Scholarship;
  }

  getScholarshipStatus(status: string): string {
    switch (status) {
      case "accepted":
        return "Kabul edildi";
      case "rejected":
        return "Reddedildi";
      case "inprogress":
        return "Devam ediyor";
      case "missing":
        return "Belge eksik";
      default:
        return "Değerlendiriliyor";
    }
  }

  async mounted() {
    await this.getScholarships();
    await this.getItems();
  }
}
