var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"my-4",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"search","label":"Ara","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('download-excel',{attrs:{"data":_vm.downloadData,"name":'tum-siparisler.xls'}},[_c('v-btn',{staticClass:"mr-2",attrs:{"text":"","color":"primary"}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-download")]),_vm._v(" İNDİR ")],1)],1),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.getItems}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("refresh")])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"loading":_vm.loading,"loading-text":"Yükleniyor..."},scopedSlots:_vm._u([{key:"item.member.email",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.member.email)+" ("+_vm._s(item.member.id)+")")])]}},{key:"item.form.student.fullName",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.toTitleCase(item.form.student.fullName)))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status == 'accepted')?_c('span',{staticClass:"green--text"},[_vm._v("Kabul")]):(item.status == 'rejected')?_c('span',{staticClass:"red--text"},[_vm._v("Red")]):(item.status == 'inevaluation')?_c('span',{staticClass:"orange--text"},[_vm._v("Değerlendiriliyor")]):(item.status == 'missing')?_c('span',{staticClass:"orange--text"},[_vm._v("Belge eksik")]):_c('span',[_vm._v("Devam ediyor")])]}},{key:"item.scholarshipId",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getScholarshipById(item.scholarshipId).title))])]}},{key:"item.dateCreated",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.dateCreated.toDate().toLocaleString("tr-TR")))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","text":"","color":"primary","to":{
                  name: 'admin-scholarship-app',
                  params: { id: item.id }
                },"exact":true}},[_vm._v("AÇ")])]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }